import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { USER_TOKEN } from "../config/env";
import {
  CLEAR_USER_INFO,
  RECEIVE_ACCESS_TOKEN,
  TOKEN_RENEW_FAIL,
  TOKEN_RENEW_REQUEST,
  TOKEN_RENEW_SUCCESS,
  TOKEN_VERIFY_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS
} from "./Types";

/**
 * TODO: Fake, not used to login via direct API call
 * @param {string} email
 * @param {string} password
 * @returns
 */
export const getClientToken = async acces_token => {
  let header = {
    Authorization: `Bearer ${acces_token}`
  };

  let response = await fetch(
    `${process.env.REACT_APP_GRAPHQL_BASE_URL}/auth/getClientToken`,
    {
      method: "GET",
      headers: header
    }
  );
  let clientToken = await response.text();
  Cookie.set("client_token", clientToken);
  return clientToken;
};
export const login = (email, password) => async dispatch => {
  dispatch({ type: USER_LOGIN_REQUEST, payload: { email, password } });
  try {
    const access_token = USER_TOKEN;
    const user = jwt_decode(access_token);
    getClientToken(access_token);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { data: user, token: access_token }
    });
    Cookie.set("userInfo", JSON.stringify(user));
    Cookie.set("access_token", access_token);
  } catch (err) {
    console.log("login.error=", err);
    dispatch({ type: USER_LOGIN_FAIL, payload: err.message });
  }
};

/**
 * Store access_token and expires in Cookies
 * @param {string} access_token
 * @param {number} expires
 * @returns
 */
export const receiveAccessToken = (access_token, expires_at) => dispatch => {
  try {
    const user = jwt_decode(access_token);
    if (expires_at > new Date().getTime() / 1000) {
      Cookie.set("userInfo", user);
      Cookie.set("access_token", access_token);
      Cookie.set("expiresAt", expires_at);
      dispatch({
        type: RECEIVE_ACCESS_TOKEN,
        payload: { user, token: access_token, expires_at }
      });
      getClientToken(access_token);
      return true;
    } else {
      throw new Error({ message: "Token expired!" });
    }
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAIL, payload: err?.message });
    return false;
  }
};

/**
 * Verify token
 * @returns Verification
 */
//This function is not called anywhere in the code. So commenting it out for now.
export const verifyToken = () => async dispatch => {
  dispatch({ type: TOKEN_VERIFY_REQUEST });
};

/**
 * Renew token
 * @returns New token
 */
export const renewToken = () => async dispatch => {
  dispatch({ type: TOKEN_RENEW_REQUEST });
  const jwt_token = Cookie.get("access_token");
  const refreshAPI = `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`;
  try {
    const modelQuery = `query{renewToken}`;
    const refreshToken = await fetch(refreshAPI, {
      method: "POST",
      headers: {
        authorization: `Bearer ${jwt_token}`
      },
      body: JSON.stringify({
        query: modelQuery
      })
    });
    const refreshAuthToken = refreshToken.json();
    if (refreshAuthToken && refreshAuthToken.data) {
      const { data } = refreshAuthToken;
      if (!data?.errors) {
        const tokenDetails = data?.data?.renewToken;
        clearCookies();
        Cookie.set("access_token", tokenDetails.token, {
          path: "/",
          domain: window.location.hostname
        });
        Cookie.set("expires", tokenDetails.expires_at, {
          path: "/",
          domain: window.location.hostname
        });
        window.authToken = tokenDetails.token;
        dispatch({ type: TOKEN_RENEW_SUCCESS, payload: tokenDetails.token });
        getClientToken(tokenDetails.token);
      } else {
        const errors = data?.errors?.map(error => {
          const fullMessage = JSON.parse(error.message);
          return `${fullMessage.code}: ${fullMessage.message}`;
        });
        console.log(errors);
        dispatch({ type: TOKEN_RENEW_FAIL, payload: errors });
        logout();
      }
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: TOKEN_RENEW_FAIL, payload: err.message });
    logout();
  }
};

const clearCookies = () => {
  Cookie.remove("access_token");
  Cookie.remove("expiresAt");
  Cookie.remove("access_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("expires", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("access_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("expires", { path: "/", domain: ".hp.com" });
};

/**
 * Clear token information on logout
 * @returns null
 */
export const logout = () => async dispatch => {
  dispatch({ type: CLEAR_USER_INFO });
  Cookie.remove("userInfo");
  Cookie.remove("access_token");
  Cookie.remove("expiresAt");
  Cookie.remove("access_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("client_token", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("expires", { path: "/", domain: ".hpicloud.net" });
  Cookie.remove("access_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("client_token", { path: "/", domain: ".hp.com" });
  Cookie.remove("expires", { path: "/", domain: ".hp.com" });
  sessionStorage.clear();
  console.log("INFO cleared");
  delete window.authToken;
  delete window.uiSpecData;
  delete window.uiSpecFileName;
  delete window._modelJson;
  localStorage.getItem("isDebug") === "true" &&
    console.log("window attributes cleared");
};
